import { BaseElement } from '../01-base/BaseElement';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6';

class HeaderElement extends BaseElement {
    properties() {
        return {
            navOpen: false,
            subNavOpen: false,
            searchActive: false,
            maxWidth: 1024,
        };
    }

    watch() {
        return {
            navOpen: (_, open) => {
                if (open && !this.searchActive) {
                    this.$refs.mainNavi.classList.remove('d-none');
                    this.$refs.close.classList.remove('d-none');
                    this.$refs.burgerMenu.classList.add('d-none');
                    this.$refs.search.classList.add('disabled');
                } else {
                    this.$refs.mainNavi.classList.add('d-none');
                    this.$refs.close.classList.add('d-none');
                    this.$refs.burgerMenu.classList.remove('d-none');
                    this.$refs.search.classList.remove('disabled');
                }
                if (open && this.searchActive) {
                    this.navOpen = false;
                    this.searchActive = false;
                }
            },
        };
    }

    events() {
        return {
            '[data-nav-toggle]': {
                click: () => {
                    this.navOpen = !this.navOpen;
                },
            },
            '[data-sub-navi-toggle]': {
                click: ({ target, currentTarget }) => {
                    this.subNavOpen = !this.subNavOpen;
                    const mainItem = target.closest('[ref="mainNavItem"]');
                    const subItem = mainItem.querySelector('[ref="subNav"]');

                    if (this.subNavOpen) {
                        subItem.classList.remove('d-none');
                        currentTarget.style.transform = 'rotate(180deg)';
                    } else {
                        subItem.classList.add('d-none');
                        currentTarget.style.transform = 'rotate(0deg)';
                    }
                },
            },
        };
    }
}

customElements.define('header-element', HeaderElement);
